import { BaseCard } from '@/lib/types/index';

export const tarotDeck: BaseCard[] = [
    {
        "id": "00",
        "title": {
            "en": "The Fool"
        },
        "arcana": "major",
        "number": "0",
        "suit": "",
        "hebrewLetter": "א",
        "sign": "☉\uFE0E",  // Sun
        "description": "With light step, as if earth and its trammels had little power to restrain him, a young man in gorgeous vestments pauses at the brink of a precipice among the great heights of the world; he surveys the blue distance before him-its expanse of sky rather than the prospect below. His act of eager walking is still indicated, though he is stationary at the given moment; his dog is still bounding. The edge which opens on the depth has no terror; it is as if angels were waiting to uphold him, if it came about that he leaped from the height. His countenance is full of intelligence and expectant dream. He has a rose in one hand and in the other a costly wand, from which depends over his right shoulder a wallet curiously embroidered. He is a prince of the other world on his travels through this one-all amidst the morning glory, in the keen air. The sun, which shines behind him, knows whence he came, whither he is going, and how he will return by another path after many days. He is the spirit in search of experience. Many symbols of the Instituted Mysteries are summarized in this card, which reverses, under high warrants, all the confusions that have preceded it.",
        "reversedDescription": "When reversed, the card shows the young man still perched at the precipice, but his lightheartedness now reveals a lack of caution. The boundless potential he once sought becomes naivety, where he might step too far without considering consequences. The sun behind him fades, indicating a shadowed path, one where he may wander without direction or discernment. His boundless trust in the universe becomes misplaced confidence, leaving him vulnerable to missteps or foolish choices. The rose he holds loses its bloom, and his dog, instead of bounding with joy, appears wary, sensing the risks his master overlooks. In this position, the card warns of careless decisions and the need for grounded wisdom.",
        "imagePath": "/cards/00.jpg"
    },
    {
        "id": "01",
        "title": {
            "en": "The Magician"
        },
        "arcana": "major",
        "number": "I",
        "suit": "",
        "hebrewLetter": "ב",
        "sign": "☿\uFE0E",  // Mercury
        "description": "A youthful figure in the robe of a magician, having the countenance of divine Apollo, with smile of confidence and shining eyes. Above his head is the mysterious sign of the Holy Spirit, the sign of life, like an endless cord, forming the figure 8 in a horizontal position. About his waist is a serpent-cincture, the serpent appearing to devour its own tail. This is familiar to most as a conventional symbol of eternity, but here it indicates more especially the eternity of attainment in the spirit. In the Magician's right hand is a wand raised towards heaven, while the left hand is pointing to the earth. This dual sign is known in very high grades of the Instituted Mysteries; it shows the descent of grace, virtue and light, drawn from things above and derived to things below. The suggestion throughout is therefore the possession and communication of the Powers and Gifts of the Spirit. On the table in front of the Magician are the symbols of the four Tarot suits, signifying the elements of natural life, which lie like counters before the adept, and he adapts them as he wills. Beneath are roses and lilies, the flos campi and lilium convallium, changed into garden flowers, to show the culture of aspiration. This card signifies the divine motive in man, reflecting God, the will in the liberation of its union with that which is above. It is also the unity of individual being on all planes, and in a very high sense it is thought, in the fixation thereof. With further reference to what I have called the sign of life and its connection with the number 8, it may be remembered that Christian Gnosticism speaks of rebirth in Christ as a change 'unto the Ogdoad.' The mystic number is termed Jerusalem above, the Land flowing with Milk and Honey, the Holy Spirit and the Land of the Lord. According to Martinism, 8 is the number of Christ.",
        "reversedDescription": "When reversed, the Magician's mastery over the elements and spiritual connection may signal manipulation, deception, or a misuse of power. His confidence becomes overconfidence, leading to trickery or misdirected energy. The once-harmonious flow between heaven and earth is disrupted, symbolizing a loss of alignment or a distortion of intent. The tools on the table, symbols of natural life, may be scattered or used without purpose, reflecting inner chaos or a lack of direction. In this position, the card warns against using talents or knowledge for selfish ends, urging a re-evaluation of one's motivations and actions. The roses and lilies, symbols of cultivated aspiration, may wither, indicating that ambition has strayed from its higher calling. The reversed Magician suggests the need to reconnect with genuine intent and ethical integrity.",    
        "imagePath": "/cards/01.jpg"
    },
    {
        "id": "02",
        "title": {
            "en": "The High Priestess"
        },
        "arcana": "major",
        "number": "II",
        "suit": "",
        "hebrewLetter": "ג",
        "sign": "☽\uFE0E",  // Moon
        "description": "She has the lunar crescent at her feet, a horned diadem on her head, with a globe in the middle place, and a large solar cross on her breast. The scroll in her hands is inscribed with the word Tora, signifying the Greater Law, the Secret Law and the second sense of the Word. It is partly covered by her mantle, to show that some things are implied and some spoken. She is seated between the white and black pillars — J. and B. — of the mystic Temple, and the veil of the Temple is behind her: it is embroidered with palms and pomegranates. The vestments are flowing and gauzy, and the mantle suggests light — a shimmering radiance. She has been called occult Science on the threshold of the Sanctuary of Isis, but she is really the Secret Church, the House which is of God and man. She represents also the Second Marriage of the Prince who is no longer of this world; she is the spiritual Bride and Mother, the daughter of the stars and the Higher Garden of Eden. She is, in fine, the Queen of the borrowed light, but this is the light of all. She is the Moon nourished by the milk of the Supernal Mother.",
        "reversedDescription": "When reversed, the card reveals the shadowed aspects of wisdom and intuition, where knowledge becomes hidden or distorted, and secrecy turns into isolation. The Priestess’s deep connection to the mysteries may become blocked, leading to confusion or clouded judgment. Her symbol of borrowed light might now suggest a dependence on others’ insight without tapping into one's own inner wisdom. The veil between the pillars may feel impenetrable, reflecting a struggle to understand the truths or secrets that lie beyond. This card reversed warns against ignoring intuition, advises caution around hidden motives, and suggests the need to confront inner uncertainties rather than seeking external validation.",    
        "imagePath": "/cards/02.jpg"
    },
    {
        "id": "03",
        "title": {
            "en": "The Empress"
        },
        "arcana": "major",
        "number": "III",
        "suit": "",
        "hebrewLetter": "ד",
        "sign": "♀\uFE0E",  // Venus
        "description": "A stately figure, seated, having rich vestments and royal aspect, as of a daughter of heaven and earth. Her diadem is of twelve stars, gathered in a cluster. The symbol of Venus is on the shield which rests near her. A field of corn is ripening in front of her, and beyond there is a fall of water. The sceptre which she bears is surmounted by the globe of this world. She is the inferior Garden of Eden, the Earthly Paradise, all that is symbolized by the visible house of man. She is not Regina coeli, but she is still refugium peccatorum, the fruitful mother of thousands. There are also certain aspects in which she has been correctly described as desire and the wings thereof, as the woman clothed with the sun, as Gloria Mundi and the veil of the Sanctum Sanctorum; but she is not, I may add, the soul that has attained wings, unless all the symbolism is counted up another and unusual way. She is above all things universal fecundity and the outer sense of the Word. This is obvious, because there is no direct message which has been given to man like that which is borne by woman; but she does not herself carry its interpretation.",
        "reversedDescription": "When reversed, the Empress’s abundant and nurturing nature may become blocked or misdirected, resulting in stagnation, creative blocks, or over-dependence. Her fertile fields may appear barren, symbolizing a lack of growth or imbalance in personal relationships and projects. Her role as the fruitful mother and nurturer becomes strained, potentially leading to possessiveness, neglect, or smothering tendencies. The reversed Empress warns against ignoring self-care and encourages the rediscovery of one's natural rhythm and creative flow. It suggests that a return to simplicity and reconnection with the self is necessary to overcome obstacles and restore harmony.",    
        "imagePath": "/cards/03.jpg"
    },
    {
        "id": "04",
        "title": {
            "en": "The Emperor"
        },
        "arcana": "major",
        "number": "IV",
        "suit": "",
        "hebrewLetter": "ה",
        "sign": "♈\uFE0E",  // Aries
        "description": "He has a form of the Crux ansata for his sceptre and a globe in his left hand. He is a crowned monarch — commanding, stately, seated on a throne, the arms of which are fronted by rams' heads. He is executive and realization, the power of this world, here clothed with the highest of its natural attributes. He is occasionally represented as seated on a cubic stone, which, however, confuses some of the issues. He is the virile power, to which the Empress responds, and in this sense is he who seeks to remove the Veil of Isis; yet she remains virgo intacta.",
        "reversedDescription": "When reversed, the Emperor’s commanding authority may transform into rigidity, dominance, or a misuse of power. His natural leadership becomes overbearing, leading to control issues, stubbornness, or an unwillingness to consider others' perspectives. Instead of being a pillar of support, he may impose his will to the detriment of relationships or progress. This card warns against becoming overly authoritarian or resistant to change. The reversed Emperor suggests a need to temper power with flexibility and to reconnect with compassion and understanding, rather than strictly adhering to rules and order.",
        "imagePath": "/cards/04.jpg"
    },
    {
        "id": "05",
        "title": {
            "en": "The Hierophant"
        },
        "arcana": "major",
        "number": "V",
        "suit": "",
        "hebrewLetter": "ו",
        "sign": "♉\uFE0E",  // Taurus
        "description": "He wears the triple crown and is seated between two pillars, but they are not those of the Temple which is guarded by the High Priestess. In his left hand he holds a sceptre terminating in the triple cross, and with his right hand he gives the well-known ecclesiastical sign which is called that of esotericism, distinguishing between the manifest and concealed part of doctrine. It is noticeable in this connexion that the High Priestess makes no sign. At his feet are the crossed keys, and two priestly ministers in albs kneel before him. He has been usually called the Pope, which is a particular application of the more general office that he symbolizes. He is the ruling power of external religion, as the High Priestess is the prevailing genius of the esoteric, withdrawn power. The proper meanings of this card have suffered woeful admixture from nearly all hands. Grand Orient says truly that the Hierophant is the power of the keys, exoteric orthodox doctrine, and the outer side of the life which leads to the doctrine; but he is certainly not the prince of occult doctrine, as another commentator has suggested.",
        "reversedDescription": "When reversed, the Hierophant’s symbol of tradition and external authority may turn into rigidity, dogmatism, or a rejection of personal belief in favor of conformity. His influence over doctrine becomes oppressive, leading to inflexibility or an inability to adapt to new ideas. The card warns against blindly following rules or structures without question, encouraging a break from tradition or the exploration of alternative viewpoints. The reversed Hierophant suggests that one should seek personal truths and embrace individuality, rather than relying solely on established norms or institutions for guidance.",
        "imagePath": "/cards/05.jpg"
    },
    {
        "id": "06",
        "title": {
            "en": "The Lovers"
        },
        "arcana": "major",
        "number": "VI",
        "suit": "",
        "hebrewLetter": "ז",
        "sign": "♊\uFE0E",  // Gemini
        "description": "The sun shines in the zenith, and beneath is a great winged figure with arms extended, pouring down influences. In the foreground are two human figures, male and female, unveiled before each other, as if Adam and Eve when they first occupied the paradise of the earthly body. Behind the man is the Tree of Life, bearing twelve fruits, and the Tree of the Knowledge of Good and Evil is behind the woman; the serpent is twining round it. The figures suggest youth, virginity, innocence and love before it is contaminated by gross material desire. This is in all simplicity the card of human love, here exhibited as part of the way, the truth and the life. It replaces, by recourse to first principles, the old card of marriage, which I have described previously, and the later follies which depicted man between vice and virtue. In a very high sense, the card is a mystery of the Covenant and Sabbath.",
        "reversedDescription": "When reversed, the Lovers card may signal conflict, miscommunication, or a disconnect in relationships. The harmonious union of love and understanding may give way to doubt, imbalance, or temptation, leading to difficult choices or moral dilemmas. The card suggests a loss of trust or harmony, where decisions become clouded by external influences or internal struggles. The reversed Lovers warns against impulsive choices, urging careful consideration of commitments and values. It encourages reconnection with one’s inner truth and the clarity of purpose to overcome disharmony or temptation.",
        "imagePath": "/cards/06.jpg"
    },
    {
        "id": "07",
        "title": {
            "en": "The Chariot"
        },
        "arcana": "major",
        "number": "VII",
        "suit": "",
        "hebrewLetter": "ח",
        "sign": "♋\uFE0E",  // Cancer
        "description": "An erect and princely figure carrying a drawn sword and corresponding, broadly speaking, to the traditional description which I have given in the first part. On the shoulders of the victorious hero are supposed to be the Urim and Thummim. He has led captivity captive; he is conquest on all planes — in the mind, in science, in progress, in certain trials of initiation. He has thus replied to the sphinx, and it is on this account that I have accepted the variation of Éliphas Lévi; two sphinxes thus draw his chariot. He is above all things triumph in the mind.",
        "reversedDescription": "When reversed, the Chariot may symbolize a loss of control, a lack of direction, or a struggle with inner demons. The triumphant hero may now appear defeated or overwhelmed, with the sword seemingly turned against him. The Urim and Thummim, symbols of divine guidance, may now suggest confusion or a lack of clarity in decision-making. The reversed Chariot warns against pride and overconfidence, urging humility and a reevaluation of one's actions and motivations. It encourages a return to inner strength and a reliance on one's own inner wisdom to navigate challenges.",
        "imagePath": "/cards/07.jpg"
    },
    {
        "id": "08",
        "title": {
            "en": "Strength"
        },
        "arcana": "major",
        "number": "VIII",
        "suit": "",
        "hebrewLetter": "ט",
        "sign": "♌\uFE0E",  // Leo
        "description": "A woman, over whose head there broods the same symbol of life which we have seen in the card of the Magician, is closing the jaws of a lion. The only point in which this design differs from the conventional presentations is that her beneficent fortitude has already subdued the lion, which is being led by a chain of flowers. For reasons which satisfy myself, this card has been interchanged with that of justice, which is usually numbered eight. As the variation carries nothing with it which will signify to the reader, there is no cause for explanation. Fortitude, in one of its most exalted aspects, is connected with the Divine Mystery of Union; the virtue, of course, operates in all planes, and hence draws on all in its symbolism. It connects also with innocentia inviolata, and with the strength which resides in contemplation.",
        "reversedDescription": "When reversed, the Strength card may symbolize a loss of fortitude or a struggle with inner demons. The woman may now appear defeated or overwhelmed, with the lion seeming to overpower her. The symbol of life, once a source of strength, may now suggest confusion or a lack of clarity in one's resolve. The reversed Strength warns against pride and overconfidence, urging humility and a reevaluation of one's actions and motivations. It encourages a return to inner strength and a reliance on one's own inner wisdom to navigate challenges.",
        "imagePath": "/cards/08.jpg"
    },
    {
        "id": "09",
        "title": {
            "en": "The Hermit"
        },
        "arcana": "major",
        "number": "IX",
        "suit": "",
        "hebrewLetter": "י",
        "sign": "♍\uFE0E",  // Virgo
        "description": "The variation from the conventional models in this card is only that the lamp is not enveloped partially in the mantle of its bearer, who blends the idea of the Ancient of Days with the Light of the World It is a star which shines in the lantern. I have said that this is a card of attainment, and to extend this conception the figure is seen holding up his beacon on an eminence. Therefore the Hermit is not, as Court de Gebelin explained, a wise man in search of truth and justice; nor is he, as a later explanation proposes, an especial example of experience. His beacon intimates that \"where I am, you also may be. \"",
        "reversedDescription": "When reversed, the Hermit may symbolize a loss of guidance or a struggle with inner demons. The figure may now appear isolated or withdrawn, with the lamp suggesting confusion or a lack of clarity in one's purpose. The reversed Hermit warns against isolation and encourages a return to inner strength and a reliance on one's own inner wisdom to navigate challenges. It encourages a return to inner strength and a reliance on one's own inner wisdom to navigate challenges.",
        "imagePath": "/cards/09.jpg"
    },
    {
        "id": "10",
        "title": {
            "en": "Wheel of Fortune"
        },
        "arcana": "major",
        "number": "X",
        "suit": "",
        "hebrewLetter": "כ",
        "sign": "♎\uFE0E",  // Libra
        "description": "In this symbol I have again followed the reconstruction of Éliphas Lévi, who has furnished several variants. It is legitimate — as I have intimated — to use Egyptian symbolism when this serves our purpose, provided that no theory of origin is implied therein. I have, however, presented Typhon in his serpent form. The symbolism is, of course, not exclusively Egyptian, as the four Living Creatures of Ezekiel occupy the angles of the card, and the wheel itself follows other indications of Lévi in respect of Ezekiel's vision, as illustrative of the particular Tarot Key. With the French occultist, and in the design itself, the symbolic picture stands for the perpetual motion of a fluidic universe and for the flux of human life. The Sphinx is the equilibrium therein. The transliteration of Taro as Rota is inscribed on the wheel, counterchanged with the letters of the Divine Name — to shew that Providence is imphed through all. But this is the Divine intention within, and the similar intention without is exemplified by the four Living Creatures. Sometimes the sphinx is represented couchant on a pedestal above, which defrauds the symbolism by stultifying the essential idea of stability amidst movement.",
        "reversedDescription": "When reversed, the Wheel of Fortune may symbolize a loss of control or a struggle with fate. The wheel may now appear stagnant or out of alignment, with the Sphinx suggesting confusion or a lack of clarity in one's purpose. The reversed Wheel of Fortune warns against being overly passive or accepting circumstances beyond one's control. It encourages a return to inner strength and a reliance on one's own inner wisdom to navigate challenges. It encourages a return to inner strength and a reliance on one's own inner wisdom to navigate challenges.",
        "imagePath": "/cards/10.jpg"
    },
    {
        "id": "11",
        "title": {
            "en": "Justice"
        },
        "arcana": "major",
        "number": "XI",
        "suit": "",
        "hebrewLetter": "ל",
        "sign": "♒\uFE0E",  // Water
        "description": "As this card follows the traditional symbolism and carries above all its obvious meanings, there is little to say regarding it outside the few considerations collected in the first part, to which the reader is referred.",
        "reversedDescription": "When reversed, the Justice card may symbolize a loss of fairness or a struggle with inner demons. The scales may now appear unbalanced, with the sword suggesting confusion or a lack of clarity in one's purpose. The reversed Justice warns against being overly passive or accepting circumstances beyond one's control. It encourages a return to inner strength and a reliance on one's own inner wisdom to navigate challenges.",
        "imagePath": "/cards/11.jpg"
    },
    {
        "id": "12",
        "title": {
            "en": "The Hanged Man"
        },
        "arcana": "major",
        "number": "XII",
        "suit": "",
        "hebrewLetter": "מ",
        "sign": "♏\uFE0E",  // Scorpio
        "description": "The gallows from which he is suspended forms a Tau cross, while the figure — from the position of the legs — forms a fylfot cross. There is a nimbus about the head of the seeming martyr. It should be noted (1) that the tree of sacrifice is living wood, with leaves thereon; (2) that the face expresses deep entrancement, not suffering; (3) that the figure, as a whole, suggests life in suspension, but life and not death. It is a card of profound significance, but all the significance is veiled. One of his editors suggests that Éliphas Lévi did not know the meaning, which is unquestionable nor did the editor himself. It has been called falsely a card of martyrdom, a card a of prudence, a card of the Great Work, a card of duty; but we may exhaust all published interpretations and find only vanity. I will say very simply on my own part that it expresses the relation, in one of its aspects, between the Divine and the Universe.",
        "reversedDescription": "When reversed, the Hanged Man may symbolize a loss of faith or a struggle with inner demons. The figure may now appear defeated or overwhelmed, with the gallows suggesting confusion or a lack of clarity in one's purpose. The reversed Hanged Man warns against being overly passive or accepting circumstances beyond one's control. It encourages a return to inner strength and a reliance on one's own inner wisdom to navigate challenges.",
        "imagePath": "/cards/12.jpg"
    },
    {
        "id": "13",
        "title": {
            "en": "Death"
        },
        "arcana": "major",
        "number": "XIII",
        "suit": "",
        "hebrewLetter": "נ",
        "sign": "♐\uFE0E",  // Sagittarius
        "description": "The veil or mask of life is perpetuated in change, transformation and passage from lower to higher, and this is more fitly represented in the rectified Tarot by one of the apocalyptic visions than by the crude notion of the reaping skeleton. Behind it lies the whole world of ascent in the spirit. The mysterious horseman moves slowly, bearing a black banner emblazoned with the Mystic Rose, which signifies life. Between two pillars on the verge of the horizon there shines the sun of immortality. The horseman carries no visible weapon, but king and child and maiden fall before him, while a prelate with clasped hands awaits his end.",
        "reversedDescription": "When reversed, the Death card may symbolize a loss of control or a struggle with fate. The horseman may now appear defeated or overwhelmed, with the black banner suggesting confusion or a lack of clarity in one's purpose. The reversed Death warns against being overly passive or accepting circumstances beyond one's control. It encourages a return to inner strength and a reliance on one's own inner wisdom to navigate challenges.",
        "imagePath": "/cards/13.jpg"
    },
    {
        "id": "14",
        "title": {
            "en": "Temperance"
        },
        "arcana": "major",
        "number": "XIV",
        "suit": "",
        "hebrewLetter": "ס",
        "sign": "♑\uFE0E",  // Capricorn
        "description": "A winged angel, with the sign of the sun upon his forehead and on his breast the square and triangle of the septenary. I speak of him in the masculine sense, but the figure is neither male nor female. It is held to be pouring the essences of life from chalice to chalice. It has one foot upon the earth and one upon waters, thus illustrating the nature of the essences. A direct path goes up to certain heights on the verge of the horizon, and above there is a great light, through which a crown is seen vaguely. Hereof is some part of the Secret of Eternal Life, as it is possible to man in his incarnation. All the conventional emblems are renounced herein.",
        "reversedDescription": "When reversed, the Temperance card may symbolize a loss of balance or a struggle with inner demons. The angel may now appear unbalanced, with the chalices suggesting confusion or a lack of clarity in one's purpose. The reversed Temperance warns against being overly passive or accepting circumstances beyond one's control. It encourages a return to inner strength and a reliance on one's own inner wisdom to navigate challenges.",
        "imagePath": "/cards/14.jpg"
    },
    {
        "id": "15",
        "title": {
            "en": "The Devil"
        },
        "arcana": "major",
        "number": "XV",
        "suit": "",
        "hebrewLetter": "ע",
        "sign": "♒\uFE0E",  // Aquarius
        "description": "The design is an accommodation, mean or harmony, between several motives mentioned in the first part. The Horned Goat of Mendes, with wings like those of a bat, is standing on an altar. At the pit of the stomach there is the sign of Mercury. The right hand is upraised and extended, being the reverse of that benediction which is given by the Hierophant in the fifth card. In the left hand there is a great flaming torch, inverted towards the earth. A reversed pentagram is on the forehead. There is a ring in front of the altar, from which two chains are carried to the necks of two figures, male and female. These are analogous with those of the fifth card, as if Adam and Eve after the Fall. Hereof is the chain and fatality of the material life.",
        "reversedDescription": "When reversed, the Devil may symbolize a loss of control or a struggle with fate. The chains may now appear to bind rather than liberate, with the figure suggesting confusion or a lack of clarity in one's purpose. The reversed Devil warns against being overly passive or accepting circumstances beyond one's control. It encourages a return to inner strength and a reliance on one's own inner wisdom to navigate challenges.",
        "imagePath": "/cards/15.jpg"
    },
    {
        "id": "16",
        "title": {
            "en": "The Tower"
        },
        "arcana": "major",
        "number": "XVI",
        "suit": "",
        "hebrewLetter": "פ",
        "sign": "♂\uFE0E",  // Mars
        "description": "Occult explanations attached to this card are meagre and mostly disconcerting. It is idle to indicate that it depicts min in all its aspects, because it bears this evidence on the surface. It is said further that it contains the first allusion to a material building, but I do not conceive that the Tower is more or less material than the pillars which we have met with in three previous cases. I see nothing to warrant Papus in supposing that it is literally the fall of Adam, but there is more in favour of his alternative — that it signifies the materialization of the spiritual word. The bibliographer Christian imagines that it is the downfall of the mind, seeking to penetrate the mystery of God. I agree rather with Grand Orient that it is the ruin of the House of We, when evil has prevailed therein, and above all that it is the rending of a House of Doctrine. I understand that the reference is, however, to a House of Falsehood. It illustrates also in the most comprehensive way the old truth that \"except the Lord build the house, they labour in vain that build it.\"",
        "reversedDescription": "When reversed, the Tower may symbolize a loss of control or a struggle with fate. The tower may now appear to crumble or fall, with the figure suggesting confusion or a lack of clarity in one's purpose. The reversed Tower warns against being overly passive or accepting circumstances beyond one's control. It encourages a return to inner strength and a reliance on one's own inner wisdom to navigate challenges.",
        "imagePath": "/cards/16.jpg"
    },
    {
        "id": "17",
        "title": {
            "en": "The Star"
        },
        "arcana": "major",
        "number": "XVII",
        "suit": "",
        "hebrewLetter": "צ",
        "sign": "♓\uFE0E",  // Pisces
        "description": "A great, radiant star of eight rays, surrounded by seven lesser stars — also of eight rays. The female figure in the foreground is entirely naked. Her left knee is on the land and her right foot upon the water. She pours Water of Life from two great ewers, irrigating sea and land. Behind her is rising ground and on the right a shrub or tree, whereon a bird alights. The figure expresses eternal youth and beauty. The star is l'étoile flamboyante, which appears in Masonic symbolism, but has been confused therein. That which the figure communicates to the living scene is the substance of the heavens and the elements. It has been said truly that the mottoes of this card are \"Waters of Life freely\" and \"Gifts of the Spirit.\"",
        "reversedDescription": "When reversed, the Star may symbolize a loss of faith or a struggle with inner demons. The star may now appear to fade or disappear, with the figure suggesting confusion or a lack of clarity in one's purpose. The reversed Star warns against being overly passive or accepting circumstances beyond one's control. It encourages a return to inner strength and a reliance on one's own inner wisdom to navigate challenges.",
        "imagePath": "/cards/17.jpg"
    },
    {
        "id": "18",
        "title": {
            "en": "The Moon"
        },
        "arcana": "major",
        "number": "XVIII",
        "suit": "",
        "hebrewLetter": "ק",
        "sign": "♃\uFE0E",  // Jupiter
        "description": "The distinction between this card and some of the conventional types is that the moon is increasing on what is called the side of mercy, to the right of the observer. It has sixteen chief and sixteen secondary rays. The card represents life of the imagination apart from life of the spirit. The path between the towers is the issue into the unknown. The dog and wolf are the fears of the natural mind in the presence of that place of exit, when there is only reflected light to guide it.",
        "reversedDescription": "When reversed, the Moon may symbolize a loss of clarity or a struggle with inner demons. The moon may now appear to be in shadow or hidden, with the figure suggesting confusion or a lack of clarity in one's purpose. The reversed Moon warns against being overly passive or accepting circumstances beyond one's control. It encourages a return to inner strength and a reliance on one's own inner wisdom to navigate challenges.",
        "imagePath": "/cards/18.jpg"
    },
    {
        "id": "19",
        "title": {
            "en": "The Sun"
        },
        "arcana": "major",
        "number": "XIX",
        "suit": "",
        "hebrewLetter": "ר",
        "sign": "♄\uFE0E",  // Saturn
        "description": "The naked child mounted on a white horse and displaying a red standard has been mentioned already as the better symbolism connected with this card. It is the destiny of the Supernatural East and the great and holy light which goes before the endless procession of humanity, coming out from the walled garden of the sensitive life and passing on the journey home. The card signifies, therefore, the transit from the manifest light of this world, represented by the glorious sun of earth, to the light of the world to come, which goes before aspiration and is typified by the heart of a child .",
        "reversedDescription": "When reversed, the Sun may symbolize a loss of clarity or a struggle with inner demons. The sun may now appear to be hidden or obscured, with the figure suggesting confusion or a lack of clarity in one's purpose. The reversed Sun warns against being overly passive or accepting circumstances beyond one's control. It encourages a return to inner strength and a reliance on one's own inner wisdom to navigate challenges.",
        "imagePath": "/cards/19.jpg"
    },
    {
        "id": "20",
        "title": {
            "en": "Judgement"
        },
        "arcana": "major",
        "number": "XX",
        "suit": "",
        "hebrewLetter": "ש",
        "sign": "🜂\uFE0E",  // Fire
        "description": "I have said that this symbol is essentially invariable in all Tarot sets, or at least the variations do not alter its character. The great angel is here encompassed by clouds, but he blows his bannered trumpet, and the cross as usual is displayed on the banner. The dead are rising from their tombs — a woman on the right, a man on the left hand, and between them their child, whose back is turned. But in this card there are more than three who are restored, and it has been thought worth while to make this variation as illustrating the insufficiency of current explanations. It should be noted that all the figures are as one in the wonder, adoration and ecstacy expressed by their attitudes. It is the card which registers the accomplishment of the great work of transformation in answer to the summons of the Supernal — which summons is heard and answered from within.",
        "reversedDescription": "When reversed, the Judgement card may symbolize a loss of clarity or a struggle with inner demons. The angel may now appear to be overshadowed or hidden, with the clouds suggesting confusion or a lack of clarity in one's purpose. The reversed Judgement warns against being overly passive or accepting circumstances beyond one's control. It encourages a return to inner strength and a reliance on one's own inner wisdom to navigate challenges.",
        "imagePath": "/cards/20.jpg"
    },
    {
        "id": "21",
        "title": {
            "en": "The World"
        },
        "arcana": "major",
        "number": "XXI",
        "suit": "",
        "hebrewLetter": "ת",
        "sign": "⊕\uFE0E",  // Earth
        "description": "As this final message of the Major Trumps is unchanged — and indeed unchangeable — in respect of its design, it has been partly described already regarding its deeper sense. It represents also the perfection and end of the Cosmos, the secret which is within it, the rapture of the universe when it understands itself in God. It is further the state of the soul in the consciousness of Divine Vision, reflected from the self-knowing spirit. But these meanings are without prejudice to that which I have said concerning it on the material side.",
        "reversedDescription": "When reversed, the World may symbolize a loss of clarity or a struggle with inner demons. The world may now appear to be incomplete or lacking, with the figure suggesting confusion or a lack of clarity in one's purpose. The reversed World warns against being overly passive or accepting circumstances beyond one's control. It encourages a return to inner strength and a reliance on one's own inner wisdom to navigate challenges.",
        "imagePath": "/cards/21.jpg"
    },
    {
        "id": "c01",
        "title": {
            "en": "Ace of Cups"
        },
        "arcana": "minor",
        "number": "01",
        "suit": "Cups",
        "hebrewLetter": "",
        "sign": "",
        "description": "The waters are beneath, and thereon are water-lilies; the hand issues from the cloud, holding in its palm the cup, from which four streams are pouring; a dove, bearing in its bill a cross-marked Host, descends to place the Wafer in the Cup; the dew of water is falling on all sides. It is an intimation of that which may lie behind the Lesser Arcana. Divinatory Meanings: House of the true heart, joy, content, abode, nourishment, abundance, fertility; Holy Table, felicity hereof.",
        "reversedDescription": "House of the false heart, mutation, instability, revolution.",
        "imagePath": "/cards/c01.jpg"
    },
    {
        "id": "c02",
        "title": {
            "en": "Two of Cups"
        },
        "arcana": "minor",
        "number": "02",
        "suit": "Cups",
        "hebrewLetter": "",
        "sign": "",
        "description": "A youth and maiden are pledging one another, and above their cups rises the Caduceus of Hermes, between the great wings of which there appears a lion's head. It is a variant of a sign which is found in a few old examples of this card. Some curious emblematical meanings are attached to it, but they do not concern us in this place. Divinatory Meanings: Love, passion, friendship, affinity, union, concord, sympathy, the interrelation of the sexes, and — as a suggestion apart from all offices of divination — that desire which is not in Nature, but by which Nature is sanctified.",
        "reversedDescription": "House of the false heart, mutation, instability, revolution.",
        "imagePath": "/cards/c02.jpg"
    },
    {
        "id": "c03",
        "title": {
            "en": "Three of Cups"
        },
        "arcana": "minor",
        "number": "03",
        "suit": "Cups",
        "hebrewLetter": "",
        "sign": "",
        "description": "Maidens in a garden-ground with cups uplifted, as if pledging one another. Divinatory Meanings: The conclusion of any matter in plenty, perfection and merriment; happy issue, victory, fulfilment, solace, healing,",
        "reversedDescription": "Expedition, dispatch, achievement, end. It signifies also the side of excess in physical enjoyment, and the pleasures of the senses.",
        "imagePath": "/cards/c03.jpg"
    },
    {
        "id": "c04",
        "title": {
            "en": "Four of Cups"
        },
        "arcana": "minor",
        "number": "04",
        "suit": "Cups",
        "hebrewLetter": "",
        "sign": "",
        "description": "A young man is seated under a tree and contemplates three cups set on the grass before him; an arm issuing from a cloud offers him another cup. His expression notwithstanding is one of discontent with his environment. Divinatory Meanings: Weariness, disgust, aversion, imaginary vexations, as if the wine of this world had caused satiety only; another wine, as if a fairy gift, is now offered the wastrel, but he sees no consolation therein. This is also a card of blended pleasure.",
        "reversedDescription": "Novelty, presage, new instruction, new relations.",
        "imagePath": "/cards/c04.jpg"
    },
    {
        "id": "c05",
        "title": {
            "en": "Five of Cups"
        },
        "arcana": "minor",
        "number": "05",
        "suit": "Cups",
        "hebrewLetter": "",
        "sign": "",
        "description": "A dark, cloaked figure, looking sideways at three prone cups two others stand upright behind him; a bridge is in the background, leading to a small keep or holding. Divanatory Meanings: It is a card of loss, but something remains over; three have been taken, but two are left; it is a card of inheritance, patrimony, transmission, but not corresponding to expectations; with some interpreters it is a card of marriage, but not without bitterness or frustration.",
        "reversedDescription": "News, alliances, affinity, consanguinity, ancestry, return, false projects.",
        "imagePath": "/cards/c05.jpg"
    },
    {
        "id": "c06",
        "title": {
            "en": "Six of Cups"
        },
        "arcana": "minor",
        "number": "06",
        "suit": "Cups",
        "hebrewLetter": "",
        "sign": "",
        "description": "Children in an old garden, their cups filled with flowers. Divinatory Meanings: A card of the past and of memories, looking back, as — for example — on childhood; happiness, enjoyment, but coming rather from the past; things that have vanished. Another reading reverses this, giving new relations, new knowledge, new environment, and then the children are disporting in an unfamiliar precinct.",
        "reversedDescription": "The future, renewal, that which will come to pass presently.",
        "imagePath": "/cards/c06.jpg"
    },
    {
        "id": "c07",
        "title": {
            "en": "Seven of Cups"
        },
        "arcana": "minor",
        "number": "07",
        "suit": "Cups",
        "hebrewLetter": "",
        "sign": "",
        "description": "Strange chalices of vision, but the images are more especially those of the fantastic spirit. Divinatory Meanings: Fairy favours, images of reflection, sentiment, imagination, things seen in the glass of contemplation; some attainment in these degrees, but nothing permanent or substantial is suggested.",
        "reversedDescription": "Desire, will, determination, project.",
        "imagePath": "/cards/c07.jpg"
    },
    {
        "id": "c08",
        "title": {
            "en": "Eight of Cups"
        },
        "arcana": "minor",
        "number": "08",
        "suit": "Cups",
        "hebrewLetter": "",
        "sign": "",
        "description": "A man of dejected aspect is deserting the cups of his felicity, enterprise, undertaking or previous concern. Divinatory Meanings: The card speaks for itself on the surface, but other readings are entirely antithetical — giving joy, mildness, timidity, honour, modesty. In practice, it is usually found that the card shews the decline of a matter, or that a matter which has been thought to be important is really of slight consequence — either for good or evil.",
        "reversedDescription": "Great joy, happiness, feasting.",
        "imagePath": "/cards/c08.jpg"
    },
    {
        "id": "c09",
        "title": {
            "en": "Nine of Cups"
        },
        "arcana": "minor",
        "number": "09",
        "suit": "Cups",
        "hebrewLetter": "",
        "sign": "",
        "description": "A goodly personage has feasted to his heart's content, and abundant refreshment of wine is on the arched counter behind him, seeming to indicate that the future is also assured. The picture offers the material side only, but there are other aspects. Divinatory Meanings: Concord, contentment, physical bien-être; also victory, success, advantage; satisfaction for the Querent or person for whom the consultation is made.",
        "reversedDescription": "Truth, loyalty, liberty; but the readings vary and include mistakes, imperfections, etc.",
        "imagePath": "/cards/c09.jpg"
    },
    {
        "id": "c10",
        "title": {
            "en": "Ten of Cups"
        },
        "arcana": "minor",
        "number": "10",
        "suit": "Cups",
        "hebrewLetter": "",
        "sign": "",
        "description": "Appearance of Cups in a rainbow; it is contemplated in wonder and ecstacy by a man and woman below, evidently husband and wife. His right arm is about her; his left is raised upward; she raises her right arm. The two children dancing near them have not observed the prodigy but are happy after their own manner. There is a home-scene beyond. Divinatory Meanings: Contentment, repose of the entire heart; the perfection of that state; also perfection of human love and friendship; if with several picture-cards, a person who is taking charge of the Querent's interests; also the town, village or country inhabited by the Querent.",
        "reversedDescription": "Repose of the false heart, indignation, violence.",
        "imagePath": "/cards/c10.jpg"
    },
    {
        "id": "c11",
        "title": {
            "en": "Page of Cups"
        },
        "arcana": "minor",
        "number": "11",
        "suit": "Cups",
        "hebrewLetter": "",
        "sign": "",
        "description": "A fair, pleasing, somewhat effeminate page, of studious and intent aspect, contemplates a fish rising from a cup to look at him. It is the pictures of the mind taking form. Divinatory Meanings: Fair young man, one impelled to render service and with whom the Querent will be connected; a studious youth; news, message; application, reflection, meditation; also these things directed to business.",
        "reversedDescription": "Taste, inclination, attachment, seduction, deception, artifice.",
        "imagePath": "/cards/c11.jpg"
    },
    {
        "id": "c12",
        "title": {
            "en": "Knight of Cups"
        },
        "arcana": "minor",
        "number": "12",
        "suit": "Cups",
        "hebrewLetter": "",
        "sign": "",
        "description": "Graceful, but not warlike; riding quietly, wearing a winged helmet, referring to those higher graces of the imagination which sometimes characterize this card. He too is a dreamer, but the images of the side of sense haunt him in his vision. Divinatory Meanings: Arrival, approach — sometimes that of a messenger; advances, proposition, demeanour, invitation, incitement.",
        "reversedDescription": "Trickery, artifice, subtlety, swindling, duplicity, fraud.",
        "imagePath": "/cards/c12.jpg"
    },
    {
        "id": "c13",
        "title": {
            "en": "Queen of Cups"
        },
        "arcana": "minor",
        "number": "13",
        "suit": "Cups",
        "hebrewLetter": "",
        "sign": "",
        "description": "Beautiful, fair, dreamy — as one who sees visions in a cup. This is, however, only one of her aspects; she sees, but she also acts, and her activity feeds her dream. Divinatory Meanings: Good, fair woman; honest, devoted woman, who will do service to the Querent; loving intelligence, and hence the gift of vision; success, happiness, pleasure; also wisdom, virtue; a perfect spouse and a good mother.",
        "reversedDescription": "The accounts vary; good woman; otherwise, distinguished woman but one not to be trusted; perverse woman; vice, dishonour, depravity.",
        "imagePath": "/cards/c13.jpg"
    },
    {
        "id": "c14",
        "title": {
            "en": "King of Cups"
        },
        "arcana": "minor",
        "number": "14",
        "suit": "Cups",
        "hebrewLetter": "",
        "sign": "",
        "description": "He holds a short sceptre in his left hand and a great cup in his right; his throne is set upon the sea; on one side a ship is riding and on the other a dolphin is leaping. The implicit is that the Sign of the Cup naturally refers to water, which appears in all the court cards. Divinatory Meanings: Fair man, man of business, law, or divinity; responsible, disposed to oblige the Querent; also equity, art and science, including those who profess science, law and art; creative intelligence.",
        "reversedDescription": "Dishonest, double-dealing man; roguery, exaction, injustice, vice, scandal, pillage, considerable loss.",
        "imagePath": "/cards/c14.jpg"
    },
    {
        "id": "p01",
        "title": {
            "en": "Ace of Pentacles"
        },
        "arcana": "minor",
        "number": "01",
        "suit": "Pentacles",
        "hebrewLetter": "",
        "sign": "",
        "description": "A hand — issuing, as usual, from a cloud — holds up a pentacle. Divinatory Meanings: Perfect contentment, felicity, ecstasy; also speedy intelligence; gold.",
        "reversedDescription": "The evil side of wealth, bad intelligence; also great riches. In any case it shews prosperity, comfortable material conditions, but whether these are of advantage to the possessor will depend on whether the card is reversed or not.",
        "imagePath": "/cards/p01.jpg"
    },
    {
        "id": "p02",
        "title": {
            "en": "Two of Pentacles"
        },
        "arcana": "minor",
        "number": "02",
        "suit": "Pentacles",
        "hebrewLetter": "",
        "sign": "",
        "description": "A young man, in the act of dancing, has a pentacle in either hand, and they are joined by that endless cord which is like the number 8 reversed. Divinatory Meanings: On the one hand it is represented as a card of gaiety, recreation and its connexions, which is the subject of the design; but it is read also as news and messages in writing, as obstacles, agitation, trouble, embroilment.",
        "reversedDescription": "Enforced gaiety, simulated enjoyment, literal sense, handwriting, composition, letters of exchange.",
        "imagePath": "/cards/p02.jpg"
    },
    {
        "id": "p03",
        "title": {
            "en": "Three of Pentacles"
        },
        "arcana": "minor",
        "number": "03",
        "suit": "Pentacles",
        "hebrewLetter": "",
        "sign": "",
        "description": "A sculptor at his work in a monastery. Compare the design which illustrates the Eight of Pentacles. The apprentice or amateur therein has received his reward and is now at work in earnest. Divinatory Meanings: Métier, trade, skilled labour; usually, however, regarded as a card of nobility, aristocracy, renown, glory.",
        "reversedDescription": "Mediocrity, in work and otherwise, puerility, pettiness, weakness.",
        "imagePath": "/cards/p03.jpg"
    },
    {
        "id": "p04",
        "title": {
            "en": "Four of Pentacles"
        },
        "arcana": "minor",
        "number": "04",
        "suit": "Pentacles",
        "hebrewLetter": "",
        "sign": "",
        "description": "A crowned figure, having a pentacle over his crown, clasps another with hands and arms; two pentacles are under his feet. He holds to that which he has. Divinatory Meanings: The surety of possessions, cleaving to that which one has, gift, legacy, inheritance.",
        "reversedDescription": "Suspense, delay, opposition.",
        "imagePath": "/cards/p04.jpg"
    },
    {
        "id": "p05",
        "title": {
            "en": "Five of Pentacles"
        },
        "arcana": "minor",
        "number": "05",
        "suit": "Pentacles",
        "hebrewLetter": "",
        "sign": "",
        "description": "Two mendicants in a snow-storm pass a lighted casement. Divinatory Meanings: The card foretells material trouble above all, whether in the form illustrated — that is, destitution — or otherwise. For some cartomancists, it is a card of love and lovers-wife, husband, friend, mistress; also concordance, affinities. These alternatives cannot be harmonized.",
        "reversedDescription": "Disorder, chaos, ruin, discord, profligacy.",
        "imagePath": "/cards/p05.jpg"
    },
    {
        "id": "p06",
        "title": {
            "en": "Six of Pentacles"
        },
        "arcana": "minor",
        "number": "06",
        "suit": "Pentacles",
        "hebrewLetter": "",
        "sign": "",
        "description": "A person in the guise of a merchant weighs money in a pair of scales and distributes it to the needy and distressed. It is a testimony to his own success in life, as well as to his goodness of heart. Divinatory Meanings: Presents, gifts, gratification another account says attention, vigilance now is the accepted time, present prosperity, etc.",
        "reversedDescription": "Desire, cupidity, envy, jealousy, illusion.",
        "imagePath": "/cards/p06.jpg"
    },
    {
        "id": "p07",
        "title": {
            "en": "Seven of Pentacles"
        },
        "arcana": "minor",
        "number": "07",
        "suit": "Pentacles",
        "hebrewLetter": "",
        "sign": "",
        "description": "A young man, leaning on his staff, looks intently at seven pentacles attached to a clump of greenery on his right; one would say that these were his treasures and that his heart was there. Divinatory Meanings: These are exceedingly contradictory; in the main, it is a card of money, business, barter; but one reading gives altercation, quarrels — and another innocence, ingenuity, purgation.",
        "reversedDescription": "Cause for anxiety regarding money which it may be proposed to lend.",
        "imagePath": "/cards/p07.jpg"
    },
    {
        "id": "p08",
        "title": {
            "en": "Eight of Pentacles"
        },
        "arcana": "minor",
        "number": "08",
        "suit": "Pentacles",
        "hebrewLetter": "",
        "sign": "",
        "description": "An artist in stone at his work, which he exhibits in the form of trophies. Divinatory Meanings: Work, employment, commission, craftsmanship, skill in craft and business, perhaps in the preparatory stage.",
        "reversedDescription": "Voided ambition, vanity, cupidity, exaction, usury. It may also signify the possession of skill, in the sense of the ingenious mind turned to cunning and intrigue.",
        "imagePath": "/cards/p08.jpg"
    },
    {
        "id": "p09",
        "title": {
            "en": "Nine of Pentacles"
        },
        "arcana": "minor",
        "number": "09",
        "suit": "Pentacles",
        "hebrewLetter": "",
        "sign": "",
        "description": "A woman, with a bird upon her wrist, stands amidst a great abundance of grapevines in the garden of a manorial house. It is a wide domain, suggesting plenty in all things. Possibly it is her own possession and testifies to material well-being. Divinatory Meanings: Prudence, safety, success, accomplishment, certitude, discernment.",
        "reversedDescription": "Roguery, deception, voided project, bad faith.",
        "imagePath": "/cards/p09.jpg"
    },
    {
        "id": "p10",
        "title": {
            "en": "Ten of Pentacles"
        },
        "arcana": "minor",
        "number": "10",
        "suit": "Pentacles",
        "hebrewLetter": "",
        "sign": "",
        "description": "A man and woman beneath an archway which gives entrance to a house and domain. They are accompanied by a child, who looks curiously at two dogs accosting an ancient personage seated in the foreground. The child's hand is on one of them. Divinatory Meanings: Gain, riches; family matters, archives, extraction, the abode of a family.",
        "reversedDescription": "Chance, fatality, loss, robbery, games of hazard; sometimes gift, dowry, pension.",
        "imagePath": "/cards/p10.jpg"
    },
    {
        "id": "p11",
        "title": {
            "en": "Page of Pentacles"
        },
        "arcana": "minor",
        "number": "11",
        "suit": "Pentacles",
        "hebrewLetter": "",
        "sign": "",
        "description": "A youthful figure, looking intently at the pentacle which hovers over his raised hands. He moves slowly, insensible of that which is about him. Divinatory Meanings: Application, study, scholarship, reflection another reading says news, messages and the bringer thereof; also rule, management.",
        "reversedDescription": "Prodigality, dissipation, liberality, luxury; unfavourable news.",
        "imagePath": "/cards/p11.jpg"
    },
    {
        "id": "p12",
        "title": {
            "en": "Knight of Pentacles"
        },
        "arcana": "minor",
        "number": "12",
        "suit": "Pentacles",
        "hebrewLetter": "",
        "sign": "",
        "description": "He rides a slow, enduring, heavy horse, to which his own aspect corresponds. He exhibits his symbol, but does not look therein. Divinatory Meanings: Utility, serviceableness, interest, responsibility, rectitude-all on the normal and external plane.",
        "reversedDescription": "inertia, idleness, repose of that kind, stagnation; also placidity, discouragement, carelessness.",
        "imagePath": "/cards/p12.jpg"
    },
    {
        "id": "p13",
        "title": {
            "en": "Queen of Pentacles"
        },
        "arcana": "minor",
        "number": "13",
        "suit": "Pentacles",
        "hebrewLetter": "",
        "sign": "",
        "description": "The face suggests that of a dark woman, whose qualities might be summed up in the idea of greatness of soul; she has also the serious cast of intelligence; she contemplates her symbol and may see worlds therein. Divinatory Meanings: Opulence, generosity, magnificence, security, liberty.",
        "reversedDescription": "Evil, suspicion, suspense, fear, mistrust.",
        "imagePath": "/cards/p13.jpg"
    },
    {
        "id": "p14",
        "title": {
            "en": "King of Pentacles"
        },
        "arcana": "minor",
        "number": "14",
        "suit": "Pentacles",
        "hebrewLetter": "",
        "sign": "",
        "description": "The physical and emotional nature to which this card is attributed is dark, ardent, lithe, animated, impassioned, noble. The King uplifts a flowering wand, and wears, like his three correspondences in the remaining suits, what is called a cap of maintenance beneath his crown. He connects with the symbol of the lion, which is emblazoned on the back of his throne. Divinatory Meanings: Dark man, friendly, countryman, generally married, honest and conscientious. The card always signifies honesty, and may mean news concerning an unexpected heritage to fall in before very long.",
        "reversedDescription": "Vice, weakness, ugliness, perversity, corruption, peril.",
        "imagePath": "/cards/p14.jpg"
    },
    {
        "id": "s01",
        "title": {
            "en": "Ace of Swords"
        },
        "arcana": "minor",
        "number": "01",
        "suit": "Swords",
        "hebrewLetter": "",
        "sign": "",
        "description": "A hand issues from a cloud, grasping as word, the point of which is encircled by a crown. Divinatory Meanings: Triumph, the excessive degree in everything, conquest, triumph of force. It is a card of great force, in love as well as in hatred. The crown may carry a much higher significance than comes usually within the sphere of fortune-telling.",
        "reversedDescription": "The same; burial and obsequies.",
        "imagePath": "/cards/s01.jpg"
    },
    {
        "id": "s02",
        "title": {
            "en": "Two of Swords"
        },
        "arcana": "minor",
        "number": "02",
        "suit": "Swords",
        "hebrewLetter": "",
        "sign": "",
        "description": "A hoodwinked female figure balances two swords upon her shoulders. Divinatory Meanings: Conformity and the equipoise which it suggests, courage, friendship, concord in a state of arms; another reading gives tenderness, affection, intimacy. The suggestion of harmony and other favourable readings must be considered in a qualified manner, as Swords generally are not symbolical of beneficent forces in human affairs.",
        "reversedDescription": "Imposture, falsehood, duplicity, disloyalty.",
        "imagePath": "/cards/s02.jpg"
    },
    {
        "id": "s03",
        "title": {
            "en": "Three of Swords"
        },
        "arcana": "minor",
        "number": "03",
        "suit": "Swords",
        "hebrewLetter": "",
        "sign": "",
        "description": "Three swords piercing a heart; cloud and rain behind. Divinatory Meanings: Removal, absence, delay, division, rupture, dispersion, and all that the design signifies naturally, being too simple and obvious to call for specific enumeration.",
        "reversedDescription": "Mental alienation, error, loss, distraction, disorder, confusion.",
        "imagePath": "/cards/s03.jpg"
    },
    {
        "id": "s04",
        "title": {
            "en": "Four of Swords"
        },
        "arcana": "minor",
        "number": "04",
        "suit": "Swords",
        "hebrewLetter": "",
        "sign": "",
        "description": "The effigy of a knight in the attitude of prayer, at full length upon his tomb. Divinatory Meanings: Vigilance, retreat, solitude, hermit's repose, exile, tomb and coffin. It is these last that have suggested the design.",
        "reversedDescription": "Wise administration, circumspection, economy, avarice, precaution, testament.",
        "imagePath": "/cards/s04.jpg"
    },
    {
        "id": "s05",
        "title": {
            "en": "Five of Swords"
        },
        "arcana": "minor",
        "number": "05",
        "suit": "Swords",
        "hebrewLetter": "",
        "sign": "",
        "description": "A disdainful man looks after two retreating and dejected figures. Their swords lie upon the ground. He carries two others on his left shoulder, and a third sword is in his right hand, point to earth. He is the master in possession of the field. Divinatory Meanings: Degradation, destruction, revocation, infamy, dishonour, loss, with the variants and analogues of these.",
        "reversedDescription": "The same; burial and obsequies.",
        "imagePath": "/cards/s05.jpg"
    },
    {
        "id": "s06",
        "title": {
            "en": "Six of Swords"
        },
        "arcana": "minor",
        "number": "06",
        "suit": "Swords",
        "hebrewLetter": "",
        "sign": "",
        "description": "A ferryman carrying passengers in his punt to the further shore. The course is smooth, and seeing that the freight is light, it may be noted that the work is not beyond his strength. Divinatory Meanings: journey by water, route, way, envoy, commissionary, expedient.",
        "reversedDescription": "Declaration, confession, publicity; one account says that it is a proposal of love.",
        "imagePath": "/cards/s06.jpg"
    },
    {
        "id": "s07",
        "title": {
            "en": "Seven of Swords"
        },
        "arcana": "minor",
        "number": "07",
        "suit": "Swords",
        "hebrewLetter": "",
        "sign": "",
        "description": "A man in the act of carrying away five swords rapidly; the two others of the card remain stuck in the ground. A camp is close at hand. Divinatory Meanings: Design, attempt, wish, hope, confidence; also quarrelling, a plan that may fail, annoyance. The design is uncertain in its import, because the significations are widely at variance with each other.",
        "reversedDescription": "Good advice, counsel, instruction, slander, babbling.",
        "imagePath": "/cards/s07.jpg"
    },
    {
        "id": "s08",
        "title": {
            "en": "Eight of Swords"
        },
        "arcana": "minor",
        "number": "08",
        "suit": "Swords",
        "hebrewLetter": "",
        "sign": "",
        "description": "A woman, bound and hoodwinked, with the swords of the card about her. Yet it is rather a card of temporary durance than of irretrievable bondage. Divinatory Meanings: Bad news, violent chagrin, crisis, censure, power in trammels, conflict, calumny; also sickness.",
        "reversedDescription": "Disquiet, difficulty, opposition, accident, treachery; what is unforeseen; fatality.",
        "imagePath": "/cards/s08.jpg"
    },
    {
        "id": "s09",
        "title": {
            "en": "Nine of Swords"
        },
        "arcana": "minor",
        "number": "09",
        "suit": "Swords",
        "hebrewLetter": "",
        "sign": "",
        "description": "One seated on her couch in lamentation, with the swords over her. She is as one who knows no sorrow which is like unto hers. It is a card of utter desolation. Divinatory Meanings: Death, failure, miscarriage, delay, deception, disappointment, despair.",
        "reversedDescription": "Imprisonment, suspicion, doubt, reasonable fear, shame.",
        "imagePath": "/cards/s09.jpg"
    },
    {
        "id": "s10",
        "title": {
            "en": "Ten of Swords"
        },
        "arcana": "minor",
        "number": "10",
        "suit": "Swords",
        "hebrewLetter": "",
        "sign": "",
        "description": "A prostrate figure, pierced by all the swords belonging to the card. Divinatory Meanings: Whatsoever is intimated by the design; also pain, affliction, tears, sadness, desolation. It is not especially a card of violent death.",
        "reversedDescription": "Advantage, profit, success, favour, but none of these are permanent; also power and authority.",
        "imagePath": "/cards/s10.jpg"
    },
    {
        "id": "s11",
        "title": {
            "en": "Page of Swords"
        },
        "arcana": "minor",
        "number": "11",
        "suit": "Swords",
        "hebrewLetter": "",
        "sign": "",
        "description": "A lithe, active figure holds a sword upright in both hands, while in the act of swift walking. He is passing over rugged land, and about his way the clouds are collocated wildly. He is alert and lithe, looking this way and that, as if an expected enemy might appear at any moment. Divinatory Meanings: Authority, overseeing, secret service, vigilance, spying, examination, and the qualities thereto belonging.",
        "reversedDescription": "More evil side of these qualities; what is unforeseen, unprepared state; sickness is also intimated.",
        "imagePath": "/cards/s11.jpg"
    },
    {
        "id": "s12",
        "title": {
            "en": "Knight of Swords"
        },
        "arcana": "minor",
        "number": "12",
        "suit": "Swords",
        "hebrewLetter": "",
        "sign": "",
        "description": "He is riding in full course, as if scattering his enemies. In the design he is really a prototypical hero of romantic chivalry. He might almost be Galahad, whose sword is swift and sure because he is clean of heart. Divinatory Meanings: Skill, bravery, capacity, defence, address, enmity, wrath, war, destruction, opposition, resistance, ruin. There is therefore a sense in which the card signifies death, but it carries this meaning only in its proximity to other cards of fatality.",
        "reversedDescription": "Imprudence, incapacity, extravagance.",
        "imagePath": "/cards/s12.jpg"
    },
    {
        "id": "s13",
        "title": {
            "en": "Queen of Swords"
        },
        "arcana": "minor",
        "number": "13",
        "suit": "Swords",
        "hebrewLetter": "",
        "sign": "",
        "description": "Her right hand raises the weapon vertically and the hilt rests on an arm of her royal chair the left hand is extended, the arm raised her countenance is severe but chastened; it suggests familiarity with sorrow. It does not represent mercy, and, her sword notwithstanding, she is scarcely a symbol of power. Divinatory Meanings: Widowhood, female sadness and embarrassment, absence, sterility, mourning, privation, separation.",
        "reversedDescription": "Malice, bigotry, artifice, prudery, bale, deceit.",
        "imagePath": "/cards/s13.jpg"
    },
    {
        "id": "s14",
        "title": {
            "en": "King of Swords"
        },
        "arcana": "minor",
        "number": "14",
        "suit": "Swords",
        "hebrewLetter": "",
        "sign": "",
        "description": "He sits in judgment, holding the unsheathed sign of his suit. He recalls, of course, the conventional Symbol of justice in the Trumps Major, and he may represent this virtue, but he is rather the power of life and death, in virtue of his office. Divinatory Meanings: Whatsoever arises out of the idea of judgment and all its connexions-power, command, authority, militant intelligence, law, offices of the crown, and so forth.",
        "reversedDescription": "Cruelty, perversity, barbarity, perfidy, evil intention.",
        "imagePath": "/cards/s14.jpg"
    },
    {
        "id": "w01",
        "title": {
            "en": "Ace of Wands"
        },
        "arcana": "minor",
        "number": "01",
        "suit": "Wands",
        "hebrewLetter": "",
        "sign": "",
        "description": "A hand issuing from a cloud grasps a stout wand or club. Divinatory Meanings: Creation, invention, enterprise, the powers which result in these; principle, beginning, source; birth, family, origin, and in a sense the virility which is behind them; the starting point of enterprises; according to another account, money, fortune, inheritance.",
        "reversedDescription": "Fall, decadence, ruin, perdition, to perish also a certain clouded joy.",
        "imagePath": "/cards/w01.jpg"
    },
    {
        "id": "w02",
        "title": {
            "en": "Two of Wands"
        },
        "arcana": "minor",
        "number": "02",
        "suit": "Wands",
        "hebrewLetter": "",
        "sign": "",
        "description": "A tall man looks from a battlemented roof over sea and shore; he holds a globe in his right hand, while a staff in his left rests on the battlement; another is fixed in a ring. The Rose and Cross and Lily should be noticed on the left side. Divinatory Meanings: Between the alternative readings there is no marriage possible; on the one hand, riches, fortune, magnificence; on the other, physical suffering, disease, chagrin, sadness, mortification. The design gives one suggestion; here is a lord overlooking his dominion and alternately contemplating a globe; it looks like the malady, the mortification, the sadness of Alexander amidst the grandeur of this world's wealth.",
        "reversedDescription": "Surprise, wonder, enchantment, emotion, trouble, fear.",
        "imagePath": "/cards/w02.jpg"
    },
    {
        "id": "w03",
        "title": {
            "en": "Three of Wands"
        },
        "arcana": "minor",
        "number": "03",
        "suit": "Wands",
        "hebrewLetter": "",
        "sign": "",
        "description": "A calm, stately personage, with his back turned, looking from a cliff's edge at ships passing over the sea. Three staves are planted in the ground, and he leans slightly on one of them. Divinatory Meanings: He symbolizes established strength, enterprise, effort, trade, commerce, discovery; those are his ships, bearing his merchandise, which are sailing over the sea. The card also signifies able co-operation in business, as if the successful merchant prince were looking from his side towards yours with a view to help you.",
        "reversedDescription": "The end of troubles, suspension or cessation of adversity, toil and disappointment.",
        "imagePath": "/cards/w03.jpg"
    },
    {
        "id": "w04",
        "title": {
            "en": "Four of Wands"
        },
        "arcana": "minor",
        "number": "04",
        "suit": "Wands",
        "hebrewLetter": "",
        "sign": "",
        "description": "From the four great staves planted in the foreground there is a great garland suspended; two female figures uplift nosegays; at their side is a bridge over a moat, leading to an old manorial house. Divinatory Meanings: They are for once almost on the surface — country life, haven of refuge, a species of domestic harvest-home, repose, concord, harmony, prosperity, peace, and the perfected work of these.",
        "reversedDescription": "The meaning remains unaltered; it is prosperity, increase, felicity, beauty, embellishment.",
        "imagePath": "/cards/w04.jpg"
    },
    {
        "id": "w05",
        "title": {
            "en": "Five of Wands"
        },
        "arcana": "minor",
        "number": "05",
        "suit": "Wands",
        "hebrewLetter": "",
        "sign": "",
        "description": "A posse of youths, who are brandishing staves, as if in sport or strife. It is mimic warfare, and hereto correspond the Divinatory Meanings: Imitation, as, for example, sham fight, but also the strenuous competition and struggle of the search after riches and fortune. In this sense it connects with the battle of life. Hence some attributions say that it is a card of gold, gain, opulence.",
        "reversedDescription": "Litigation, disputes, trickery, contradiction.",
        "imagePath": "/cards/w05.jpg"
    },
    {
        "id": "w06",
        "title": {
            "en": "Six of Wands"
        },
        "arcana": "minor",
        "number": "06",
        "suit": "Wands",
        "hebrewLetter": "",
        "sign": "",
        "description": "A laurelled horseman bears one staff adorned with a laurel crown; footmen with staves are at his side. Divinatory Meanings: The card has been so designed that it can cover several significations; on the surface, it is a victor triumphing, but it is also great news, such as might be carried in state by the King's courier; it is expectation crowned with its own desire, the crown of hope, and so forth.",
        "reversedDescription": "Apprehension, fear, as of a victorious enemy at the gate; treachery, disloyalty, as of gates being opened to the enemy; also indefinite delay.",
        "imagePath": "/cards/w06.jpg"
    },
    {
        "id": "w07",
        "title": {
            "en": "Seven of Wands"
        },
        "arcana": "minor",
        "number": "07",
        "suit": "Wands",
        "hebrewLetter": "",
        "sign": "",
        "description": "A young man on a craggy eminence brandishing a staff; six other staves are raised towards him from below. Divinatory Meanings: It is a card of valour, for, on the surface, six are attacking one, who has, however, the vantage position. On the intellectual plane, it signifies discussion, wordy strife; in business — negotiations, war of trade, barter, competition. It is further a card of success, for the combatant is on the top and his enemies may be unable to reach him.",
        "reversedDescription": "Perplexity, embarrassments, anxiety. It is also a caution against indecision.",
        "imagePath": "/cards/w07.jpg"
    },
    {
        "id": "w08",
        "title": {
            "en": "Eight of Wands"
        },
        "arcana": "minor",
        "number": "08",
        "suit": "Wands",
        "hebrewLetter": "",
        "sign": "",
        "description": "The card represents motion through the immovable-a flight of wands through an open country; but they draw to the term of their course. That which they signify is at hand; it may be even on the threshold. Divinatory Meanings: Activity in undertakings, the path of such activity, swiftness, as that of an express messenger; great haste, great hope, speed towards an end which promises assured felicity; generally, that which is on the move; also the arrows of love.",
        "reversedDescription": "Arrows of jealousy, internal dispute, stingings of conscience, quarrels; and domestic disputes for persons who are married.",
        "imagePath": "/cards/w08.jpg"
    },
    {
        "id": "w09",
        "title": {
            "en": "Nine of Wands"
        },
        "arcana": "minor",
        "number": "09",
        "suit": "Wands",
        "hebrewLetter": "",
        "sign": "",
        "description": "The figure leans upon his staff and has an expectant look, as if awaiting an enemy. Behind are eight other staves — erect, in orderly disposition, like a palisade. Divinatory Meanings: The card signifies strength in opposition. If attacked, the person will meet an onslaught boldly; and his build shews, that he may prove a formidable antagonist. With this main significance there are all its possible adjuncts — delay, suspension, adjournment.",
        "reversedDescription": "Obstacles, adversity, calamity.",
        "imagePath": "/cards/w09.jpg"
    },
    {
        "id": "w10",
        "title": {
            "en": "Ten of Wands"
        },
        "arcana": "minor",
        "number": "10",
        "suit": "Wands",
        "hebrewLetter": "",
        "sign": "",
        "description": "A man oppressed by the weight of the ten staves which he is carrying. Divinatory Meanings: A card of many significances, and some of the readings cannot be harmonized. I set aside that which connects it with honour and good faith. The chief meaning is oppression simply, but it is also fortune, gain, any kind of success, and then it is the oppression of these things. It is also a card of false-seeming, disguise, perfidy. The place which the figure is approaching may suffer from the rods that he carries. Success is stultified if the Nine of Swords follows, and if it is a question of a lawsuit, there will be certain loss.",
        "reversedDescription": "Contrarieties, difficulties, intrigues, and their analogies.",
        "imagePath": "/cards/w10.jpg"
    },
    {
        "id": "w11",
        "title": {
            "en": "Page of Wands"
        },
        "arcana": "minor",
        "number": "11",
        "suit": "Wands",
        "hebrewLetter": "",
        "sign": "",
        "description": "In a scene similar to the former, a young man stands in the act of proclamation. He is unknown but faithful, and his tidings are strange. Divinatory Meanings: Dark young man, faithful, a lover, an envoy, a postman. Beside a man, he will bear favourable testimony concerning him. A dangerous rival, if followed by the Page of Cups. Has the chief qualities of his suit. He may signify family intelligence.",
        "reversedDescription": "Anecdotes, announcements, evil news. Also indecision and the instability which accompanies it.",
        "imagePath": "/cards/w11.jpg"
    },
    {
        "id": "w12",
        "title": {
            "en": "Knight of Wands"
        },
        "arcana": "minor",
        "number": "12",
        "suit": "Wands",
        "hebrewLetter": "",
        "sign": "",
        "description": "He is shewn as if upon a journey, armed with a short wand, and although mailed is not on a warlike errand. He is passing mounds or pyramids. The motion of the horse is a key to the character of its rider, and suggests the precipitate mood, or things connected therewith. Divinatory Meanings: Departure, absence, flight, emigration. A dark young man, friendly. Change of residence.",
        "reversedDescription": "Rupture, division, interruption, discord.",
        "imagePath": "/cards/w12.jpg"
    },
    {
        "id": "w13",
        "title": {
            "en": "Queen of Wands"
        },
        "arcana": "minor",
        "number": "13",
        "suit": "Wands",
        "hebrewLetter": "",
        "sign": "",
        "description": "The Wands throughout this suit are always in leaf, as it is a suit of life and animation. Emotionally and otherwise, the Queen's personality corresponds to that of the King, but is more magnetic. Divinatory Meanings: A dark woman, countrywoman, friendly, chaste, loving, honourable. If the card beside her signifies a man, she is well disposed towards him; if a woman, she is interested in the Querent. Also, love of money, or a certain success in business.",
        "reversedDescription": "Good, economical, obliging, serviceable. Signifies also — but in certain positions and in the neighbourhood of other cards tending in such directions — opposition, jealousy, even deceit and infidelity.",
        "imagePath": "/cards/w13.jpg"
    },
    {
        "id": "w14",
        "title": {
            "en": "King of Wands"
        },
        "arcana": "minor",
        "number": "14",
        "suit": "Wands",
        "hebrewLetter": "",
        "sign": "",
        "description": "The physical and emotional nature to which this card is attributed is dark, ardent, lithe, animated, impassioned, noble. The King uplifts a flowering wand, and wears, like his three correspondences in the remaining suits, what is called a cap of maintenance beneath his crown. He connects with the symbol of the lion, which is emblazoned on the back of his throne. Divinatory Meanings: Dark man, friendly, countryman, generally married, honest and conscientious. The card always signifies honesty, and may mean news concerning an unexpected heritage to fall in before very long.",
        "reversedDescription": "Good, but severe; austere, yet tolerant.",
        "imagePath": "/cards/w14.jpg"
    }
];